import React, { useEffect, useState } from 'react';
import DownloadIcon from 'mdi-react/DownloadOutlineIcon';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@mui/material';

import * as contractActions from '@store/contracts/actions';
import PageHeader from '@components/Layout/PageHeader';
import PageContent from '@components/Layout/PageContent';
import LabelWithValue from '@components/Common/LabelWithValue';
import { LicensePlate } from '@components/Car/LicensePlate';
import { Button } from '@components/Common/Button';
import Loader from '@components/Common/Loader';
import { BUTTON_TYPES } from '@constants';
import Alert from '@components/Common/Alert';
import AnimatedLoader from '@components/Common/AnimatedLoader';
import ProfileInfoBlock from '@components/ProfileInfoBlock';

import { Api } from '@api';
import { DATE_FORMATS } from '@helpers/constants';
import { dateFormatter } from '@services/formatters/util/DateTime.formatters';
import { priceToGermanFormatter } from '@services/utils/Number.formatters';
import { formatMileage } from '@services/formatters';
import { t } from '@helpers/i18n';
import './ContractDetails.styles.scss';

const ContractDetails = () => {
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [displayErrorModal, setDisplayErrorModal] = useState(false);

  const dispatch = useDispatch();
  const params = useParams();
  const { contractId } = params;

  const { loading = true, contractDetails = {} } = useSelector((state) => state.contracts);
  const { auth } = useSelector((state) => state.auth);
  const { profile } = useSelector((state) => state.profile);

  useEffect(() => {
    if (contractId !== contractDetails?.contractId) {
      dispatch(contractActions.contractDetailsGet({ userId: auth.user.id, contractId }));
    }
  }, [contractId]);

  const renderHeader = () => (
    <div className="header-ticket-row">
      <div className={'contract-details-heading-title'}>{t('navigation.contracts')}</div>
      <div className="header-info-row">
        <ProfileInfoBlock
          name={contractDetails?.contractNumber}
          company={`${dateFormatter(contractDetails?.begin, DATE_FORMATS.full_DMY_dot)} - `}
          customerNumber={dateFormatter(contractDetails?.end, DATE_FORMATS.full_DMY_dot)}
          avatar={contractDetails?.contractIcon}
          licensePlate={contractDetails?.licencePlate}
        />
      </div>
    </div>
  );

  const handleFailedDownload = () => {
    setDownloadLoading(false);
    setDisplayErrorModal(true);
  };

  const fetchPdfBlob = async (id, type, name) => {
    const response = await Api.media.getContractPdf({ id, type, name });
    if (!response?.data?.blob) {
      return null;
    }
    return response.data;
  };

  const downloadContractPdf = async () => {
    try {
      setDownloadLoading(true);
      if (!contractDetails.pdfs.length) {
        handleFailedDownload();
        return;
      }
      for (const pdf of contractDetails.pdfs) {
        const { documentId, documentType, documentName } = pdf;
        const { blob } = await fetchPdfBlob(documentId, documentType, documentName);
        if (blob.length) {
          const linkSource = `data:application/pdf;base64,${blob}`;
          const downloadLink = document.createElement('a');
          downloadLink.href = linkSource;
          downloadLink.download = documentName;
          downloadLink.click();
        } else {
          setDisplayErrorModal(true);
          setDownloadLoading(false);
        }
      }
      setDownloadLoading(false);
    } catch (e) {
      console.error(e);
      setDownloadLoading(false);
    }
  };

  if (loading) {
    return <AnimatedLoader />;
  }

  return (
    <div id="contract-details-container" className="details-container">
      <PageHeader text={t('back')} profile={true} backLink={'/contracts'}>
        {renderHeader()}
      </PageHeader>
      <PageContent>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} lg={5} xl={4} className="left-column">
            <LabelWithValue
              label={t('contracts.contractNumber')}
              value={contractDetails?.contractNumber}
              customClass="detailsRow"
            />
            <LabelWithValue
              label={t('contracts.handoverDate')}
              value={dateFormatter(contractDetails?.begin, DATE_FORMATS.full_DMY_dot)}
              customClass="detailsRow"
            />
            <LabelWithValue
              label={t('contracts.returnDate')}
              value={dateFormatter(contractDetails?.end, DATE_FORMATS.full_DMY_dot)}
              customClass="detailsRow"
            />
            <LabelWithValue
              label={t('contracts.mileageMonth')}
              value={`${formatMileage(contractDetails?.mileageMonth, profile.language)} ${t('lbl_km')}`}
              customClass="detailsRow"
            />
            <LabelWithValue
              label={t('contracts.mileage')}
              value={`${formatMileage(contractDetails?.mileage, profile.language)} ${t('lbl_km')}`}
              customClass="detailsRow"
            />
            <LabelWithValue
              label={t('contracts.handoverLocation')}
              value={contractDetails?.handoverLocation.length ? contractDetails?.handoverLocation : '-'}
              customClass="detailsRow"
            />
            <LabelWithValue
              label={t('contracts.returnLocation')}
              value={contractDetails?.returnLocation.length ? contractDetails?.returnLocation : '-'}
              customClass="detailsRow"
            />
            <LabelWithValue
              label={t('contracts.vehicleType')}
              value={`${contractDetails?.vehicleBrand} ${contractDetails?.vehicleModel}`}
              customClass="detailsRow"
            />
            <LabelWithValue
              label={t('contracts.licensePlate')}
              value={<LicensePlate country="D" license={contractDetails?.licencePlate} />}
              customClass="detailsRow"
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4} className="right-column">
            <LabelWithValue
              label={t('contracts.monthlyRate')}
              value={priceToGermanFormatter(contractDetails?.monthlyRate)}
              customClass="detailsRow"
            />
            {contractDetails?.addOptions.map((option, index) => (
              <LabelWithValue
                label={`\u2022 ${option.name}`}
                value={priceToGermanFormatter(option?.price)}
                key={index}
                customClass="detailsRow"
              />
            ))}
            <hr className="details-separator-line" />
            <LabelWithValue
              label={t('contracts.totalRentNet')}
              value={priceToGermanFormatter(contractDetails?.totalRentNet)}
              customClass="detailsRow"
            />
            <LabelWithValue
              label={t('contracts.taxes')}
              value={priceToGermanFormatter(contractDetails?.taxes)}
              customClass="detailsRow"
            />
            <hr className="details-separator-line" />
            <LabelWithValue
              label={t('contracts.totalRentGross')}
              value={priceToGermanFormatter(contractDetails?.totalRent)}
              customClass="detailsRow"
            />
            <LabelWithValue
              label={t('contracts.deposit')}
              value={priceToGermanFormatter(contractDetails?.deposit)}
              customClass="detailsRow"
            />
            <LabelWithValue
              label={t('contracts.deductable')}
              value={priceToGermanFormatter(contractDetails?.deductable)}
              customClass="detailsRow"
            />
          </Grid>
        </Grid>
        <div className="download-button-wrapper">
          <Button
            text={t('registration-download')}
            onClick={() => downloadContractPdf()}
            type={BUTTON_TYPES.WARNING}
            icon={downloadLoading ? <Loader /> : <DownloadIcon />}
            isDisabled={downloadLoading}
            isFlat
          />
        </div>
        <Alert visible={displayErrorModal} onClick={() => setDisplayErrorModal(false)} title={t('no_pdf_data')} />
      </PageContent>
    </div>
  );
};

export default ContractDetails;

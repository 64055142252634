import React from 'react';
import SimpleCard from '@components/Card/SimpleCard';
import Avatar from '@components/User/Avatar';
import classnames from 'classnames';
import { dateFormatter } from '@services/formatters/util/DateTime.formatters';
import './ContractCard.styles.scss';
import { LicensePlate } from '@components/Car/LicensePlate';
import { DATE_FORMATS } from '@helpers/constants';

const ContractCard = (props) => {
  const { contract, onContractClick } = props;
  const { begin, end, contractIcon, contractNumber, isActive, licencePlate, id: contractId } = contract;

  const renderLeft = () => <Avatar src={contractIcon} iconBackgroundColor={'transparent'} small />;

  const renderPrimaryContent = () => (
    <div>
      <p>{contractNumber}</p>
    </div>
  );

  const renderRight = () => (
    <>
      <p>{dateFormatter(begin, DATE_FORMATS.full_DMY_dot)}</p>
      <p>{dateFormatter(end, DATE_FORMATS.full_DMY_dot)}</p>
      <LicensePlate country="D" license={licencePlate} />
    </>
  );

  return (
    <article
      className={classnames('contract-card', {
        contract_border_green: isActive === '1',
        contract_border_grey: isActive === '0'
      })}>
      <SimpleCard
        primaryContent={renderPrimaryContent}
        renderLeft={renderLeft}
        renderRight={renderRight}
        onClick={() => onContractClick(contractId)}
      />
    </article>
  );
};

export default React.memo(ContractCard);
